import Link from 'next/link';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Header from "../components/Header";

export default function Custom404() {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Disc Golf Performance Lab</title>
        <meta name="description" content="Disc Golf Performance Lab" />
        <link rel="icon" href="./black_icon_32.png" />
      </Head>
      <Header />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <div style={{ textAlign: 'center' }}>
          <h1>404 - Page Not Found</h1>
          <p>The page you are looking for does not exist.</p>
          <Link href="/">
            Go back to the homepage
          </Link>
        </div>
      </div>
    </>
  )
}